import React from 'react';

import styles from './styles.module.scss';
import Step from './Step';

const Steps = ({ steps }) => {
  return (
    <div className={styles.base}>
      {steps.map(({ contentful_id, ...rest }, index) => (
        <Step key={contentful_id} {...rest} index={index + 1} />
      ))}
    </div>
  );
};
export default Steps;
