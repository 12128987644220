import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

const Brands = ({ title, logos }) => {
  return (
    <div className="text-center">
      <h2 className="h3">{title}</h2>
      <div className={cx(styles.base, 'd-flex')}>
        {logos.map(({ contentful_id, file, title }) => (
          <div key={contentful_id}>
            <img src={file.url} alt={title} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default Brands;
