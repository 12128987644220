import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const IGGallery = () => {
  const data = useStaticQuery(graphql`
    query IGQuery {
      allContentfulInstagramGallery {
        edges {
          node {
            id
            tiles {
              id
              igImage {
                file {
                  url
                }
              }
              igLink
            }
          }
        }
      }
    }
  `);

  const tiles = data.allContentfulInstagramGallery.edges[0].node.tiles;

  return (
    <div className="container container--medium no-padding">
      <div className="d-flex ig">
        {tiles.map(tile => (
          <a
            href={`${tile.igLink}`}
            target="_blank"
            rel="noreferrer"
            key={tile.id}
            className="ig__tile"
            style={{
              backgroundImage: `url(${tile.igImage.file.url})`,
            }}
          ></a>
        ))}
      </div>
    </div>
  );
};

export default IGGallery;
