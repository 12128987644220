import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Link } from 'gatsby';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import ContentBlock from '../components/ContentBlock/ContentBlock';
import InstagramGallery from '../components/IGGallery';
import YouTubeVideo from '../components/YouTubeVideo';
import Brands from '../components/Brands/Brands';
import Steps from '../components/Step/Steps';

const map = {
  ContentBlock,
  InstagramGallery,
  YouTubeVideo,
  Brands,
  Steps,
};
export function getComponent(componentName) {
  return map[componentName];
}

const getYouTubeID = url => {
  if (url.includes('https://youtu.be/')) {
    return url.replace('https://youtu.be/', '');
  }
  if (url.includes('youtube.com/watch')) {
    const params = new URLSearchParams(url.split('?')[1]);
    return params.get('v');
  }
};

export const renderNodeConfig = context => ({
  renderNode: {
    [INLINES.HYPERLINK]: node => {
      const external = !node.data.uri.includes('maobox.sg');
      return (
        <a href={node.data.uri} target={external ? '_blank' : '_self'}>
          {node.content[0].value}
        </a>
      );
    },
    [INLINES.ASSET_HYPERLINK]: node => {
      const asset = context.references.find(
        ref => ref.contentful_id === node.data.target.sys.id
      );
      if (asset.file.contentType.includes('pdf')) {
        return (
          <a href={asset.file.url} target="_blank">
            {node.content[0].value}
          </a>
        );
      }
      return node.content[0].value;
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const asset = context.references.find(
        ref => ref.contentful_id === node.data.target.sys.id
      );
      return <img src={asset.file.url} />;
    },
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      if (context.raw) {
        const entry = context.references.find(
          ref => ref.contentful_id === node.data.target.sys.id
        );

        if (entry.youTubeLink) {
          const youTubeID = getYouTubeID(entry.youTubeLink);
          return <YouTubeVideo videoID={youTubeID} />;
        }
      }
    },
    [INLINES.EMBEDDED_ENTRY]: node => {
      // TODO?
    },
    [INLINES.ENTRY_HYPERLINK]: (node, text) => {
      const { slug } = context.references.find(
        ref => ref.contentful_id === node.data.target.sys.id
      );
      return <Link to={slug}>{text}</Link>;
    },
  },
});
