import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

const Step = ({ index, title, image, description }) => (
  <div className={styles.step}>
    <div className={styles.image}>
      <img
        alt={title}
        src={image.file.url}
        width={image.file.details.image.width}
        height={image.file.details.image.height}
      />
    </div>
    <div>
      <div className={cx(styles.stepHeading, 'd-flex d-center d-align-start')}>
        <span className={styles.number}>{index}</span>
        <h2 className={cx('h4', styles.title)}>{title}</h2>
      </div>
      <p className={styles.description}>{description}</p>
    </div>
  </div>
);

export default Step;
