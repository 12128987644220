import React from 'react';

const YouTubeVideo = ({ videoID }) => {
  return (
    <div style={{ maxWidth: 560, margin: '0 auto' }}>
      <div className="video">
        <iframe
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${videoID}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default YouTubeVideo;
