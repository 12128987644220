import React from 'react';
import cx from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { renderNodeConfig } from '../../utils';
import styles from './styles.module.scss';

const ContentBlock = ({
  title,
  body,
  callToAction,
  displayTitle,

  textAlignment,
}) => {
  // const assets = JSON.parse(body.raw)
  //   .content.filter(node => node.nodeType === 'embedded-asset-block')
  //   .map(result => result.data.target.sys.id);

  if ([title, body, callToAction].every(field => !field)) return null;

  return (
    <div
      className={cx(
        styles.block,
        `text-${textAlignment?.toLowerCase() || 'center'}`
      )}
    >
      {title && displayTitle && <h2>{title}</h2>}
      {body &&
        documentToReactComponents(JSON.parse(body.raw), renderNodeConfig(body))}
      {callToAction && (
        <div className={styles.ctas}>
          {callToAction.map(cta => (
            <a key={cta.id} href={cta.buttonLink} className="btn">
              {cta.label}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default ContentBlock;
